<template>
  <div>
    <van-tabs color="#1989fa"
      @click="onClickTabs"
      v-model="listdata.auditState"
      sticky>
      <van-tab title="已登记"
        name="0"></van-tab>
      <van-tab title="未通过"
        name="9"></van-tab>
    </van-tabs>
    <div>
      <van-search placeholder="请选择搜索项"
        disabled
        @click="onSearch">
      </van-search>
      <van-popup v-model="search"
        position="top"
        :style="{ height: '50%' }">
        <van-field v-model="listdata.name"
          label="老人名称"
          placeholder="请输入老人名称查询" />
        <van-field v-model="listdata.idCard"
          label="老人身份证"
          placeholder="请输入老人身份证号查询" />
        <van-field v-model="listdata.tel"
          label="老人电话"
          placeholder="请输入老人电话查询" />
        <van-field v-model="listdata.startTime"
          label="起始登记时间"
          @click="startTimeShow = true"
          readonly="readonly"
          placeholder="请选择起始登记时间" />
        <van-popup v-model="startTimeShow"
          position="bottom"
          get-container="body">
          <van-datetime-picker show-toolbar
            type="datetime"
            title="选择起始登记时间"
            @cancel="startTimeShow = false"
            @confirm="onstartTime">
          </van-datetime-picker>
        </van-popup>
        <van-field v-model="listdata.endTime"
          label="结束登记时间"
          @click="endTimeShow = true"
          readonly="readonly"
          placeholder="请选择结束登记时间" />
        <van-popup v-model="endTimeShow"
          position="bottom"
          get-container="body">
          <van-datetime-picker show-toolbar
            type="datetime"
            title="选择结束登记时间"
            @cancel="endTimeShow = false"
            @confirm="onendTime">
          </van-datetime-picker>
        </van-popup>
        <van-button round
          type="info"
          style="width: 90%; margin: 10px 5% 10px"
          @click="laorenXx()">搜 索</van-button>
        <van-button round
          type="primary"
          style="width: 90%; margin: 5px 5%"
          @click="serachReset">重 置</van-button>
      </van-popup>
    </div>
    <!-- tab栏 -->
    <div class="information">
      <!-- 全部 -->
      <van-list :loading="loading"
        :finished="finished"
        offset="0"
        @load="onLoad">
        <div class="cord"
          v-for="item in listList"
          :key="item.OMId">
          <div style="line-height: 30px;position: relative;">
            <span style="font-size:20px;font-weight:550;">{{item.Name}}</span>
            <span class="Compile"
              style="right:5px">
              <van-button type="info"
                size="small"
                @click="goCompile(item)">编辑</van-button>
            </span>
            <!-- <span class="Compile"
              style="right:0px">
              <van-button type="danger"
                size="small"
                @click="delet(item.OMId)">删除</van-button>
            </span> -->
            <br />
            身份证号：{{item.IDCard}}<br />
            现住址：{{item.Address}}<br />
            登记时间：{{item.AddTime.toLocaleString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')}}<br />
            <div v-if="listdata.auditState == '9' && item.Reason != ''">未通过原因：{{item.Reason}}<br /></div>

          </div>
        </div>
      </van-list>
    </div>
    <div v-if="hidden"
      class="hidden">───── 没有更多数据了 ─────</div>
  </div>
</template>
<script>
import { GetMyOldManPage, DeleteOldMan } from "@/api/oldman";
import { getoldmanToken, setoldmanToken } from "@/utils/auth";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
import { List } from "vant";
Vue.use(List);
import { Dialog } from 'vant';
Vue.use(Dialog);
export default {
  data () {
    return {
      listdata: {
        accToken: getoldmanToken(), //token
        page: 0,
        limit: 10,
        name: "",
        auditState: "0"
      },
      listList: {},
      loading: false, //加载
      hidden: true, //没有更多数据了
      finished: false, //数据完全加载结束为 true
      search: false, //搜索框弹窗
      startTimeShow: false, //起始时间弹框
      endTimeShow: false, //结束时间弹框
      count: ""
    }
  },
  created () {
    this.laorenXx();
  },
  methods: {
    // tab栏点击
    onClickTabs (e) {
      this.listdata.auditState = e;
      this.laorenXx();
    },
    // 跳转至编辑页面
    goCompile (row) {
      console.log(row);
      var itemmark = JSON.stringify(row);
      this.$router.push({ name: 'oldmanAdd', params: { id: itemmark } })
    },
    // 起始时间选择器
    onstartTime (e) {
      this.listdata.startTime = this.dateformat(e);
      this.startTimeShow = false;
    },
    // 结束时间选择器
    onendTime (e) {
      this.listdata.endTime = this.dateformat(e);
      this.endTimeShow = false;
    },
    // 搜索框点击
    onSearch () {
      this.search = true;
    },
    // 重置按钮
    serachReset () {
      this.listdata = {
        accToken: getoldmanToken(), //token
        page: 0,
        limit: 10,
        name: "",
        idCard: "",
        tel: "",
        startTime: "",
        endTime: "",
      }
      this.search = false;
    },
    // 下拉刷新
    onLoad () {
      this.listdata.page = this.listdata.page + 1;
      this.laorenXx();
    },
    // 查询
    laorenXx: function () {
      var newlist = []
      this.search = false;
      GetMyOldManPage(this.listdata).then((res) => {
        // 加载状态结束
        this.loading = false;
        if (res.data.code == 0) {
          this.count = res.data.count
          if (res.data.count == 0) {
            this.hidden = true;
            this.finished = true;
            this.loading = false;
            // this.listList = newlist.concat(res.data.date);
            this.listList = {};
          } else {
            if (res.data.data.length == 0) {
              this.finished = true;
            } else {
              this.newlist = this.listList;
              this.listList = newlist.concat(res.data.data);
            }
          }

        } else if (res.data.code == 1) {
          Toast.fail(res.data.msg);
          this.$router.replace({ name: 'oldman' })
        } else {
          Toast.fail(res.data.msg);
        }
      })
    },
    // 删除
    delet: function (row) {
      Dialog.confirm({
        title: "提示",
        message: "确定要删除该记录?",
      })
        .then(() => {
          DeleteOldMan({ accToken: getoldmanToken(), OMId: row }).then(
            (res) => {
              console.log(res);
              if (res.data.code == 0) {
                this.laorenXx();
                Toast.success("删除成功");
              } else {
                Toast.fail(res.data.msg);
              }
            }
          );
        })
        .catch(() => { });
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let hour = val.getHours();
      let minute = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hour >= 1 && hour <= 9) {
        hour = `0${hour}`;
      }
      if (minute >= 1 && minute <= 9) {
        minute = `0${minute}`;
      }
      return `${year}-${month}-${day} ${hour}:${minute}:00`;
    },
  }
}  
</script>
<style scoped>
.cord {
  border: 1px solid #d3cfdb;
  border-radius: 10px;
  width: 90%;
  margin: 20px auto;
  padding: 10px;
  color: black;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
/deep/.van-field__control {
  font-size: 16px;
}
.Compile {
  position: absolute;
  top: 0px;
}
</style>